<template>
  <v-app>
    <modal
      :priority="isTopPriority"
      :destination="destination"
      @forced-nextlink="goNextLink()"
    >
      <template v-slot:body>
        <div class="default-pop" v-if="popType === 'loading'">
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <v-progress-circular
              indeterminate
              color="green"
            ></v-progress-circular>
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'warn'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap">
            <button v-text="cancelBtnText" type="button" @click="close()" />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'refund'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap">
            <button
              v-text="cancelBtnText"
              type="button"
              @click="next_reload_refund()"
              id="focusControl"
              v-on:keydown.enter.prevent="next_reload_refund()"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'custom_prod_delete'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div
            class="btn-wrap-multiple"
            v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
          >
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="prod_delete_next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
          <div class="btn-wrap" v-else>
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'custom_coupon_delete'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div
            class="btn-wrap-multiple"
            v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
          >
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="coupon_delete_next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
          <div class="btn-wrap" v-else>
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'custom_user_coupon_delete'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div
            class="btn-wrap-multiple"
            v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
          >
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="coupon_delete_next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
          <div class="btn-wrap" v-else>
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'custom_symptom_delete'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div
            class="btn-wrap-multiple"
            v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
          >
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="symptom_delete_next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'custom'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div
            class="btn-wrap-multiple"
            v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
          >
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
          <div class="btn-wrap" v-else>
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'customForProd'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div
            class="btn-wrap-multiple"
            v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
          >
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
          <div class="btn-wrap" v-else>
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'payment_refund'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div
            class="btn-wrap-multiple"
            v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
          >
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="payment_refund_prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="payment_refund_next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
          <div class="btn-wrap" v-else>
            <button
              v-text="cancelBtnText"
              type="button"
              class="prev"
              @click="payment_refund_prev()"
              v-if="cancelBtnText !== undefined"
            />
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="payment_refund_next()"
              v-if="confirmBtnText !== undefined"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'refer'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap-multiple">
            <button type="button" class="pdf" @click="hyperPdf()">출력</button>
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'referCancle'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap-multiple">
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next()"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'InBox_Restore'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap-multiple">
            <button
              v-text="confirmBtnText"
              type="button"
              class="activated"
              @click="next_reload()"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'printPr'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap">
            <button
              v-text="cancelBtnText"
              type="button"
              @click="printPr()"
              id="focusControl"
              v-on:keydown.enter.prevent="printPr()"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'appointment'">
          <div class="close" @click="closeAndClose()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap">
            <button
              v-text="cancelBtnText"
              type="button"
              @click="closeAndClose()"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'drawwarn'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap">
            <button
              v-text="cancelBtnText"
              type="button"
              @click="drawWarnClose()"
            />
          </div>
        </div>
        <div class="default-pop" v-if="popType === 'warnNext'">
          <div class="close" @click="close()">
            <img src="@/assets/images/x.svg" />
          </div>
          <div class="header">
            <h4 v-html="titleMutated"></h4>
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="btn-wrap">
            <button v-text="cancelBtnText" type="button" @click="closeNext()" />
          </div>
        </div>
      </template>
    </modal>
  </v-app>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import Modal from "./Modal.vue";

export default {
  props: {
    popupSet: {
      title: {
        type: String,
      },
      content: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
      },
      nextLink: {
        type: String,
      },
      prevLink: {
        type: String,
      },
      isTopPriority: {
        type: Boolean,
      },
      action: {
        type: String,
      },
      destination: {
        type: Boolean,
      },
      callFunction: {
        type: Boolean,
      },
      pdfParam: {
        type: String,
      },
      refundParam: {
        type: Object,
      },
      prodCd: {
        type: String,
      },
      prodParam: {
        type: Object,
      },
      couponParam: {
        type: Object,
      },
      symptomParam: {
        type: Object,
      },
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      input: "",
      valid: false,
      popType: this.popupSet.popType || "warn",
      title: this.popupSet.title,
      content: this.popupSet.content,
      pdfParam: this.popupSet.pdfParam,
      confirmBtnText: this.popupSet.confirmBtnText,
      cancelBtnText: this.popupSet.cancelBtnText,
      nextLink: this.popupSet.nextLink,
      prevLink: this.popupSet.prevLink,
      isTopPriority: this.popupSet.isTopPriority,
      action: this.popupSet.action,
      destination: this.popupSet.destination,
      callFunction: this.popupSet.callFunction,
      refundParam: this.popupSet.refundParam,
      prodCd: this.popupSet.prodCd,
      prodParam: this.popupSet.prodParam,
      couponParam: this.popupSet.couponParam,
      symptomParam: this.popupSet.symptomParam,
    };
  },
  watch: {},
  mounted() {
    /*const focusControl = document.getElementById("focusControl");
    console.log(focusControl);
    focusControl.focus({ preventScroll: true });*/
  },
  computed: {
    ...mapState("basic", ["checkCustomPopup"]),
    ...mapGetters("util", ["GET_PDF_URL"]),
    ...mapGetters("request", ["GET_RESP"]),
    titleMutated() {
      if (this.title !== undefined && this.title.indexOf("\n") !== -1) {
        return this.title.split("\n").join("<br />");
      } else {
        return this.title;
      }
    },
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf("\n") !== 1) {
        return this.content.split("\n").join("<br />");
      } else {
        return this.content;
      }
    },
  },
  methods: {
    ...mapMutations("basic", [
      "SET_POPUP",
      "SET_CHILD_LOADER",
      "SET_REFUND_RESULT",
    ]),
    ...mapActions("util", ["BRING_PDF_URL_ON_REFER", "BRING_PDF"]),
    ...mapActions("request", ["CANCLE_ORDER"]),
    ...mapActions("prod", ["PROD_DELETE"]),
    ...mapActions("admin", [
      "DELETE_COUPON_DATA",
      "DELETE_USER_COUPON_DATA",
      "DELETE_SYMPTOM_DATA",
    ]),
    async hyperPdf() {
      await this.callPdf();
      await this.fileCall();
    },
    async fileCall() {
      //console.log("this.GET_PDF_URL.fileName : ", this.GET_PDF_URL.fileName);

      try {
        await this.BRING_PDF(this.GET_PDF_URL.fileName).then(() => {
          const blob = new Blob([this.GET_PDF_URL], {
            type: "application/pdf",
          });
          const result = URL.createObjectURL(blob);
          //console.log("찾기", result);
          const url = "https://www.drrefer.net/pdf/" + result;
          //console.log("called_Pdf_url", url);

          window.open(result, "_blank");
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async callPdf() {
      //console.log("쓸파람", this.pdfParam);

      //유효성 검사
      if (this.pdfParam === null || this.pdfParam === undefined)
        this.pdfParam = "";

      var pdfParamBoo = /^[0-9]{8,14}$/;

      if (!pdfParamBoo.test(this.pdfParam)) {
        console.log("error : 부적합 데이터 요청");
        return;
      }

      try {
        await this.BRING_PDF_URL_ON_REFER(this.pdfParam).then(() => {});
      } catch (error) {
        console.log("error :", error);
      }
    },
    goNextLink() {
      this.$router.push(this.nextLink).catch(() => {});
    },
    payment_refund_prev() {
      //window.location.reload();
      this.SET_POPUP(false);
      this.SET_CHILD_LOADER(false);
    },
    async payment_refund_next() {
      //console.log("defaultPopNextLink: ", this.nextLink);
      //console.log("refundParam : ", this.refundParam);
      this.SET_POPUP(false);

      await this.CANCLE_ORDER(this.refundParam).then((data) => {
        //console.log(this.GET_RESP);
        if (this.GET_RESP.resultCd === "0") {
          this.SET_REFUND_RESULT(true);
        } else {
          this.SET_REFUND_RESULT(false);
        }
      });

      /*if (this.action !== undefined) {
        this.$emit("reload-pop", this.action);
      }
      if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      }
      if (this.callFunction) {
        this.$emit("callFunction");
      }*/
    },
    next() {
      //console.log("defaultPopNextLink: ", this.nextLink);
      if (this.prodCd !== "" && this.prodCd !== null) {
        //console.log(this.prodCd);
        localStorage.setItem("prodCd", this.prodCd);
      }
      this.SET_POPUP(false);
      if (this.action !== undefined) {
        this.$emit("reload-pop", this.action);
      }
      if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink).catch(() => {});
      }
      if (this.callFunction) {
        this.$emit("callFunction");
      }
    },
    next_reload() {
      //console.log("defaultPopNextLink_reload: ", this.nextLink);
      this.SET_POPUP(false);
      if (this.action !== undefined) {
        this.$emit("reload-pop", this.action);
      }
      if (this.nextLink !== undefined) {
        window.location.reload();
      }
      if (this.callFunction) {
        this.$emit("callFunction");
      }
    },
    next_reload_refund() {
      //console.log("defaultPopNextLink_reload: ", this.nextLink);
      this.SET_POPUP(false);
      window.location.reload();
    },
    async prod_delete_next() {
      //유효성 검사

      //1. 상품코드
      if (this.prodParam.prodCd === null || this.prodParam.prodCd === undefined)
        this.prodParam.prodCd = "";

      var prodCdBoo = /^[0-9]{1,5}$/;

      if (!prodCdBoo.test(this.prodParam.prodCd)) {
        this.SET_POPUP(true);
        this.popupSet.title = "상품 코드 확인";
        this.popupSet.content = "상품코드 오류";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 상품명
      if (this.prodParam.prodNm === null || this.prodParam.prodNm === undefined)
        this.prodParam.prodNm = "";

      if (
        this.prodParam.prodNm.length < 2 ||
        this.prodParam.prodNm.length > 50
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "상품명 확인";
        this.popupSet.content = "상품명 오류";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //3. 상품설명
      if (
        this.prodParam.prodDesc === null ||
        this.prodParam.prodDesc === undefined
      )
        this.prodParam.prodDesc = "";

      if (
        this.prodParam.prodDesc.length < 2 ||
        this.prodParam.prodDesc.length > 300
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "상품설명 확인";
        this.popupSet.content = "상품설명 오류";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //4. 단위명
      if (this.prodParam.unitNm === null || this.prodParam.unitNm === undefined)
        this.prodParam.unitNm = "";

      if (
        this.prodParam.unitNm.length < 1 ||
        this.prodParam.unitNm.length > 20
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "단위명 확인";
        this.popupSet.content = "단위명 오류";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //5. 금액
      if (
        this.prodParam.price.toString() === null ||
        this.prodParam.price.toString() === undefined
      )
        this.prodParam.price = "";

      var priceBoo = /^[0-9]{1,7}$/;

      if (!priceBoo.test(this.prodParam.price.toString())) {
        this.SET_POPUP(true);
        this.popupSet.title = "금액 확인";
        this.popupSet.content = "금액 오류";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //6. 적용 시작일자
      if (
        this.prodParam.aplyStrDt === null ||
        this.prodParam.aplyStrDt === undefined
      )
        this.prodParam.aplyStrDt = "";

      var aplyStrDtBoo = /^[0-9]{8,8}$/;

      if (!aplyStrDtBoo.test(this.prodParam.aplyStrDt)) {
        this.SET_POPUP(true);
        this.popupSet.title = "적용 시작일자 확인";
        this.popupSet.content = "적용 시작일자 오류";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //7. 적용 종료일자
      if (
        this.prodParam.aplyEndDt === null ||
        this.prodParam.aplyEndDt === undefined
      )
        this.prodParam.aplyEndDt = "";

      var aplyEndDtBoo = /^[0-9]{8,8}$/;

      if (!aplyEndDtBoo.test(this.prodParam.aplyEndDt)) {
        this.SET_POPUP(true);
        this.popupSet.title = "적용 종료일자 확인";
        this.popupSet.content = "적용 종료일자 오류";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      await this.PROD_DELETE(this.prodParam);
      //console.log("defaultPopNextLink: ", this.nextLink);
      if (this.prodCd !== "" && this.prodCd !== null) {
        //console.log(this.prodCd);
        localStorage.setItem("prodCd", this.prodCd);
      }
      this.SET_POPUP(false);
      if (this.action !== undefined) {
        this.$emit("reload-pop", this.action);
      }
      if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink).catch(() => {});
      }
      if (this.callFunction) {
        this.$emit("callFunction");
      }
    },
    async coupon_delete_next() {
      if (
        this.couponParam.cponId === null ||
        this.couponParam.cponId === undefined
      )
        this.couponParam.cponId = "";

      var cponIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

      if (!cponIdBoo.test(this.couponParam.cponId)) {
        this.SET_POPUP(true);
        this.popupSet.title = "존재하지 않는 쿠폰번호";
        this.popupSet.content = "존재하지 않는 쿠폰번호입니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //console.log("couponParam : ",this.couponParam);
      await this.DELETE_COUPON_DATA(this.couponParam.cponId);
      //console.log("defaultPopNextLink: ", this.nextLink);
      if (this.prodCd !== "" && this.prodCd !== null) {
        //console.log(this.prodCd);
        localStorage.setItem("prodCd", this.prodCd);
      }
      this.SET_POPUP(false);
      if (this.action !== undefined) {
        this.$emit("reload-pop", this.action);
      }
      if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink).catch(() => {});
      }
      if (this.callFunction) {
        this.$emit("callFunction");
      }
    },
    async coupon_user_delete_next() {
      //console.log("couponParam : ",this.couponParam);

      if (
        this.couponParam.regiId === null ||
        this.couponParam.regiId === undefined
      )
        this.couponParam.regiId = "";

      var regiIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

      if (!regiIdBoo.test(this.couponParam.regiId)) {
        this.SET_POPUP(true);
        this.popupSet.title = "존재하지 않는 쿠폰번호";
        this.popupSet.content = "존재하지 않는 쿠폰번호입니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      await this.DELETE_USER_COUPON_DATA(this.couponParam.regiId);
      //console.log("defaultPopNextLink: ", this.nextLink);
      if (this.prodCd !== "" && this.prodCd !== null) {
        //console.log(this.prodCd);
        localStorage.setItem("prodCd", this.prodCd);
      }
      this.SET_POPUP(false);
      if (this.action !== undefined) {
        this.$emit("reload-pop", this.action);
      }
      if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink).catch(() => {});
      }
      if (this.callFunction) {
        this.$emit("callFunction");
      }
    },
    async symptom_delete_next() {
      //console.log(this.symptomParam);
      if (
        this.symptomParam.sympId === null ||
        this.symptomParam.sympId === undefined
      )
        this.symptomParam.sympId = "";

      var sympIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{8,14}$/;

      if (!sympIdBoo.test(this.symptomParam.sympId)) {
        this.SET_POPUP(true);
        this.popupSet.title = "존재하지 않는 유사증상정보";
        this.popupSet.content = "존재하지 않는 유사증상정보번호입니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //console.log("삭제 symptomParam : ",this.symptomParam);
      await this.DELETE_SYMPTOM_DATA(this.symptomParam.sympId);
      //console.log("defaultPopNextLink: ", this.nextLink);
      this.SET_POPUP(false);
      if (this.action !== undefined) {
        this.$emit("reload-pop", this.action);
      }
      if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink).catch(() => {});
      }
      if (this.callFunction) {
        this.$emit("callFunction");
      }
    },
    prev() {
      this.SET_POPUP(false);
      this.$router.push(this.prevLink).catch(() => {});
    },
    close() {
      this.SET_POPUP(false);
    },
    drawWarnClose() {
      this.SET_POPUP(false);
      this.$router.push("/");
    },
    printPr() {
      this.SET_POPUP(false);
      this.$emit("printPr");
    },
    closeAndClose() {
      this.SET_POPUP(false);
      this.$emit("closeWindow");
    },
    closeNext() {
      this.SET_POPUP(false);
      this.$router.push(this.nextLink);
    },
  },
  created() {
    //console.log("팝업아이템", this.popupSet);
  },
};
</script>

<style lang="scss" scoped></style>
